import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import PrivacyPolicy from "../components/sections/privacy-policy"
import PrivacyPolicyNavigation
  from "../components/common/navigation/privacy-policy-navigation"

const Privacy = () => (
  <Layout>
    <SEO title="Thanatech" description="Politique de confidentialité" />
    <PrivacyPolicyNavigation />
    <PrivacyPolicy />
  </Layout>
)

export default Privacy
