import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const PrivacyPolicy = () => (
  <Section id="fonctionnalités">
    <StyledContainer>
      <SectionTitle>{"Politique de confidentialité"}</SectionTitle>
      <FeatureText>Thanatech est développé par Emmanuel Vacher.</FeatureText>
      <FeatureText>
        Thanatech est un site web et une application mobile permettant de gérer
        les opérations d'une entreprise de services funéraires. Un site web
        (https://app.thana.tech) permet aux administrateurs de gérer en temps
        réel l'activité, tandis que l'application mobile Thanatech sert
        d'assistant pour les collaborateurs en charge de réaliser les
        prestations, en leur permettant de voir toutes leurs informations de
        mission et de remonter des informations aux administrateurs.
      </FeatureText>

      <FeatureText>
        L'application mobile Thanatech accède à l'appareil photo pour permettre
        la prise en photo de documents liés à l'activité du l'utilisateur.
      </FeatureText>

      <FeatureText>
        Thanatech demande la permission "Accéder aux photos, media et fichiers
        sur votre appareil" (permission de stockage), car elle est requise par
        Android pour sauvegarder temporairement les photos sur votre appareil.
        Les photos prises peuvent être partagées avec d'autres applications si
        vous utilisez la fonction "Partager" du module de photo de l'application
        mobile Thanatech ou si vous la stockez durablement sur votre appareil.
      </FeatureText>

      <FeatureText>
        Les photos uploadées avec l'application mobile Thanatech ne sont
        partagées qu'avec les administrateurs de l'entreprise de l'utilisateur.
        Elles sont stockées au moins 5 ans après la facturation de la prestation
        pour laquelle elles ont été uploadées, pour des raisons règlementaires
        liées au secteur du funéraire.
      </FeatureText>

      <FeatureText>
        L'application mobile Thanatech collecte la géolocalisation de l'appareil
        pour permettre aux administrateurs d'organiser l'activité de
        l'entreprise. Ces données ne sont accessibles que par les
        administrateurs pour une durée de 8 jours avant leur suppression.
      </FeatureText>

      <FeatureText>
        Si vous avez des questions par rapport à cette politique de
        confidentialité, vous pouvez nous écrire à l'adresse{" "}
        <a href="mailto:contact@thana.tech">
          <b>contact@thana.tech</b>
        </a>
        .
      </FeatureText>

      <FeatureText>
        Si vous souhaitez que vos données personnelles soient supprimées,
        envoyez-nous un e-mail depuis l'adresse e-mail que vous avez utilisée
        lors de votre inscription à l'adresse{" "}
        <a href="mailto:contact@thana.tech">
          <b>contact@thana.tech</b>
        </a>
        , en précisant que vous souhaitez que vos données personnelles soient
        supprimées. Nous vous répondrons dans les meilleurs délais.
      </FeatureText>
    </StyledContainer>
  </Section>
)

export default PrivacyPolicy

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: left;
`
